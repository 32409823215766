import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery, Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

import { Container, Row, Col, Card } from "react-bootstrap"

const IndexPage2 = () => {
  const { placeholderImage123 } = useStaticQuery(
          graphql`
      query {
        placeholderImage123: file(relativePath: { eq: "services.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 50, 
              webpOptions: {quality: 50}, 
              width: 2000,
              placeholder: BLURRED,
              blurredOptions: {width: 1000},
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  const pluginImage = getImage(placeholderImage123);
  // const crowdImage = getImage(crowd);

  return (
    <Layout>
      <SEO title="ECI Services" />
      <BgImage image={pluginImage} className="subhead">
        <div className="color-overlay d-flex pt-4 justify-content-center align-items-center">
          <div className="content-box">
            <Container>
              <h1>ECI Services</h1>
              
            </Container>
          </div>
        </div>
      </BgImage>
      <Container className="singleCol orangeBarBelow lead text-left">
      <p>Event Consulting International, Inc. offers laser focused, creative, high-value-for-your-dollar independent event, meeting and incentive management services. We are headquartered in beautiful San Francisco Bay Area with a strong base of successful clients on the West Coast, East Coast and in Canada. ECI has spent 25 successful years ideating and implementing events, meetings, conferences and incentive programs.</p>
      <p>For every meeting, trip or event we always offer the same high end, personalized service, strategic thinking, innovative concepts and attention to detail.</p>
      </Container>
      <Container className="pt-5">
        
          <Row>
            <Col lg={4}>
              <Link to="/services/meeting-planning/" className="card-link">
              <Card>
                <StaticImage
                  src="../images/meeting-planning.jpg"
                  width={700}
                  quality={72}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="A Corporate Meeting"
                  className="card-img-top"
                />
                <Card.Body className="text-center">
                  <Card.Title>Meeting Planning</Card.Title>
                  
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
              </Link>
            </Col>
            <Col lg={4}>
              <Link to="/services/event-creation/" className="card-link">
              <Card>
                <StaticImage
                  src="../images/event-creation.jpg"
                  width={700}
                  quality={72}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="A Corporate Meeting"
                  className="card-img-top"
                />
                <Card.Body className="text-center">
                  <Card.Title>Event Creation</Card.Title>
                  
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
              </Link>
            </Col>
            <Col lg={4}>
              <Link to="/services/incentive-planning/" className="card-link">
              <Card>
                <StaticImage
                  src="../images/incentive.jpg"
                  width={700}
                  quality={72}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="A Corporate Meeting"
                  className="card-img-top"
                />
                <Card.Body className="text-center">
                  <Card.Title>Incentive Planning</Card.Title>
                  
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
              </Link>
            </Col>
            
          </Row>
        </Container>
      <Container className="py-5">
        
      </Container>
      {/* <BgImage image={crowdImage}>
        Test
        <div className="color-overlay d-flex py-4 justify-content-center align-items-center">
          <div className="content-box">
            <Container>
              <p className="lead"><em>"Safely Processing and Delivering Superior Value and Service to Our Customers for over 100 years."</em></p>
            </Container>
          </div>
        </div>
      </BgImage> */}
      {/* crowd.jpg */}
    </Layout>
          
  )
}

export default IndexPage2